<template>
    <div class="container">
        <div class="top">
            <div class="header">
                <van-icon name="arrow-left" color="rgba(0,0,0,0.8)" class="icon" @click="$router.go(-1)"/>
                付款
            </div>
            <div class="msg">
                <div>
                    <div class="tip">付款给个人</div>
                    <div class="name">{{ payInfo.username }}({{ payInfo.nickname }})</div>
                </div>
                <img :src="payInfo.avatar" alt="">
            </div>
        </div>
        <div class="middle">
            <div class="money">金额</div>
            <div class="number">
                <div class="label">¥</div>
                <div class="custom-input" @click="input">
                    {{ payValue }}
                </div>
            </div>
            <div class="mark">添加备注</div>
        </div>
        <div class="keyboard" :class="{keyboardActive: payValue }">
            <van-number-keyboard
                :show="isShowKeyboard"
                theme="custom"
                extra-key="."
                close-button-text="付款"
                @blur="show = false"
                v-model="payValue"
                @close="closeKeyboard"
            >
            <template v-slot:delete>
                <img src="/images/icon-del.png" style="width: 20px;height: 20px;" alt="">
            </template>
        </van-number-keyboard>
        </div>
        <!-- 支付过渡 -->
        <div class="payDur" v-if="showPayDur">
            <img src="/images/pay-wait.png" alt="">
            <div class="text">
                微信支付
            </div>
            <div class="dotList">
                <div :class="{active: payDurIndex == 0}"></div>
                <div :class="{active: payDurIndex == 1}"></div>
                <div :class="{active: payDurIndex == 2}"></div>
            </div>
        </div>
        <!-- 支付弹窗 -->
        <van-popup 
            v-model="isPay" 
            position="bottom"
            round
            class="payPop"
            >
            <div>
                <div class="top">
                    <van-icon name="cross" size="16" @click="isPay = false" />
                    <div>使用指纹</div>
                </div>
                <div class="number" :class="{number_no: inputPwdDur}">
                    <div>付款给{{ payInfo.username }}（{{ payInfo.nickname }}）</div>
                    <div class="limit"><div>¥</div><div>{{ payValue }}</div></div>
                </div>
                <div class="type" v-if="!inputPwdDur">
                    <div>付款方式</div>
                    <div class="btn" @click="showTypePop">
                        <img :src="payType.activeIcon" class="icon" alt="">
                        <div>{{ payType | filterPayType }}</div>
                        <van-icon name="arrow-down" />
                    </div>
                </div>
                <div class="pay" v-if="!isInputPwd && !inputPwdDur">
                    <div class="btn" @click="inputPwd">支付</div> 
                </div>
                <div v-if="isInputPwd" class="pwd-input">
                    <van-password-input
                        :value="pwd"
                        :gutter="10"
                    />
                    <van-number-keyboard
                        :show="isInputPwd"
                        random-key-order
                        v-model="pwd"
                    >
                    <template v-slot:delete>
                        <img src="/images/icon-del.png" style="width: 24px;height: 24px;" alt="">
                    </template>
                    </van-number-keyboard>
                </div>
                <div v-if="inputPwdDur" class="inputPwdDur">
                    <img src="/images/pay-wait.png" alt="">
                    <div class="text">
                        微信支付
                    </div>
                    <div class="dotList">
                        <div :class="{active: inputPwdDurIndex == 0, avtive1: inputPwdDurIndex == 2}"></div>
                        <div :class="{active: inputPwdDurIndex == 1, avtive1: inputPwdDurIndex == 0}"></div>
                        <div :class="{active: inputPwdDurIndex == 2, avtive1: inputPwdDurIndex == 1}"></div>
                    </div>
                </div>
            </div>
        </van-popup>
        <!-- 支付方式弹窗 -->
        <van-popup 
            v-model="isShowTypePop" 
            position="bottom"
            round
            class="shaoyishaoPop"
            >
            <div>
                <div class="shaoyishaoPopHeader">
                <van-icon name="arrow-down" color="#000" class="icon" @click="isShowTypePop = false" />
                选择付款方式
                </div>
                <div class="cash" @click="choosePayType(0)">
                    <div class="title">
                        <img src="/images/cash.png" class="icon" alt="">
                        <div class="text_main">零钱（剩余¥{{fund.coin}}）</div>
                    </div>
                    <div class="text_sub">名下所有账号年累计零钱支付超过20万元/年限额，根据央行监管规定，当前账号不能继续使用零钱支付，请选择其他支付方式进行支付
                        <span>查看帮助</span>
                    </div>
                </div>
                <div class="money" @click="choosePayType(1)">
                    <div class="title">理财</div>
                    <div class="content">
                        <div class="title">
                        <img src="/images/lingqiantong.png" class="icon" alt="">
                        <div class="text_main">零钱通（剩余¥{{ fund.coinCash}}）</div>
                        </div>
                        <div class="text">
                        <div class="text_sub">零钱通今日累计支付达到限额</div>
                        <div class="text_sub">由微众银行提供货币基金销售服务</div>
                        </div>
                    </div>
                </div>
                <div class="cardList">
                <div class="cardTitle">银行卡</div>
                <div class="card" :class="{avtiveCard: item.id == payType.id}" v-for="item in bankList" :key="item.id" @click="choosePayType(item.id)">
                    <div class="title">
                    <img :src="item.icon" class="icon" alt="">
                    <div class="name">{{ item.title }}{{ item.type }}（{{ item.no | filterBankno }}）</div>
                    <van-icon v-if="item.id==payType.id" name="success" class="icon" color="#1CA761" />
                    </div>
                    <div class="tips">
                    <div v-if="item.isOut">该银行卡单笔支付限额50000.00元,</div>
                    <div v-if="item.isOut">当前交易已超额</div>
                    </div>
                </div>
                </div>
                <div class="addCard">
                <img src="/images/add-bank.png" class="icon" alt="">
                <div style="flex: 1;">添加银行卡</div>
                <van-icon name="arrow" class="icon arrow" color="rgba(0,0,0,0.4)" />
                </div>
            </div>
        </van-popup>
    </div>

</template>
<script>
import { Dialog } from 'vant';
import { mapGetters, mapState, mapMutations } from 'vuex'
export default {
    data() {
        return {
            isShowKeyboard: false,
            isPay: false,
            payValue: '',
            showPayDur: false,
            payDurIndex: 0,
            isInputPwd: false,
            inputPwdDur: false,
            inputPwdDurIndex: 0,
            pwd: '',
            isShowTypePop: false,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            payInfo: state => state.payInfo,
            fund: state => state.fund,
            payTypeId: state => state.payTypeId
        }),
       ...mapGetters([
        'payType',
        'bankList'
       ])
    },
    filters: {
        filterBankno(no) {
            const arr = no.split(',')
            return arr[arr.length-1]
        },
        filterPayType(payType) {
            if (payType.id == 0 || payType.id == 1) {
                return payType.title
            } else {
                const arr = payType.no.split(',')
                return payType.title+payType.type + '（'+arr[arr.length-1]+'）'
            }
        }
    },
    methods: {
        ...mapMutations([
            'setPayTypeId'
        ]),
        input() {
            this.isShowKeyboard = true
        },
        closeKeyboard() {
            this.isShowKeyboard = false
            this.showPayDur = true
            let timer = setInterval(() => {
                this.payDurIndex +=1
                if (this.payDurIndex == 3) {
                    this.payDurIndex = 0
                }
            }, 1000);
            setTimeout(() => {
                clearInterval(timer)
                this.showPayDur = false
                this.isPay = true
            }, 4000);
        },
        showTypePop() {
            this.isShowTypePop = true
        },
        inputPwd() {
            this.isInputPwd = true
        },
        // 选择付款方式，更新数据
        choosePayType(id) {
            this.isShowTypePop = false
            this.setPayTypeId({ id })
        }
    },
    watch: {
        pwd() {
            if (this.pwd.length == 6) {
                this.isInputPwd = false
                this.inputPwdDur = true
                let timer = setInterval(() => {
                    this.inputPwdDurIndex += 1
                    if (this.inputPwdDurIndex == 3) {
                        this.inputPwdDurIndex = 0
                    }
                }, 1000)
                setTimeout(() => {
                    clearInterval(timer)
                    this.isPay = false
                    Dialog.confirm({
                        title: '',
                        /*message: '当前交易异常，暂无法完成支付，请注意合法使用支付账户，否则将升级限制措施。可点击“查看解决方法”提交信息以解除限制。',*/ 
                        message: '根据国家法律法规要求，因你存在交易异常行为，名下所有支付账卢(微信支付、QQ钱包)月收付款交易总额已达本月上限200000元，当前交易暂时无法完成。如有疑问，请点击“了解详情”。',
                        confirmButtonText: '了解详情',
                        confirmButtonColor: '#1f517c',
                        cancelButtonText: '确定'
                    })  .then(() => {
                             this.$router.push({ path: '/wehchat/xianshi'})
                        })
                }, 4000);
               
            }
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    display: flex;
    flex-direction: column;
}
.top {
    background: #EDEDED;
    .header {
        color: #000;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 10px;
        margin-bottom: 30px;
        .icon {
            position: absolute;
            left: 0;
        }
    }
    .msg {
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        .tip {
            font-weight: bold;
            font-size: 16px;
        }
        .name {
            font-size: 14px;
        }
        img {
            width: 42px;
            height: 42px;
        }
    }
}
.middle {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px ;
    .money {
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .number {
        display: flex;
        border-bottom: 1px solid #EDEDED;
        .label {
            font-weight: bold;
            font-size: 26px;
        }
        .custom-input {
            width: 300px;
            vertical-align: top;
            font-weight: bold;
            font-size: 30px !important;
        }
    }
    .mark {
        color: #737D96;
        margin-top: 30px;
    }
}
.keyboard {
    ::v-deep .van-number-keyboard__sidebar .van-key__wrapper:nth-of-type(1) {
        flex: 1;
        .van-key--large {
            right: 0;
        }
    }
    ::v-deep .van-number-keyboard__sidebar .van-key__wrapper:nth-of-type(2) {
        flex: 3;
        .van-key--large {
            right: 0;
            background: #9BE6BF;
        }
    }
}
.keyboardActive {
    ::v-deep .van-number-keyboard__sidebar .van-key__wrapper:nth-of-type(2) {
        flex: 3;
        .van-key--large {
            right: 0;
            background: #07C160;
        }
    }
}
.payDur {
    position: absolute;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: calc(~'50% - 50px');
    left: calc(~'50% - 50px');
    background: #4C4C4C;
    border-radius: 8px;
    img {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .text {
        color: #fff;
    }
    .dotList {
        display: flex;
        margin-top: 10px;
        div {
            width: 4px;
            height: 4px;
            background: #818181;
            border-radius: 50%;
            margin-left: 2px;
            margin-right: 2px;
        }
        .active {
            background: #fff;
        }
    }
}
.payPop {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 26px;
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        margin-bottom: 30px;
    }
    .top>div {
        color: #737D96;
    }
    .number {
        text-align: center;
        padding-bottom: 30px;
        border-bottom: 1px solid #EDEDED;
        font-size: 16px;
        .limit {
            font-weight: bold;
            font-size: 28px;
            height: 28px;
            line-height: 24px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: 8px;

            div:nth-of-type(1) {
                height: 20px;
                font-size: 20px;
                line-height: 20px;
                margin-right: 6px;
            }
            
        }
    }
    .number_no {
        border: none;
    }
    .type {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #9C9C9C;
        margin-top: 10px;
        .btn {
            display: flex;
            align-items: center;
            img {
                width: 16px;
                height: 16px;
                margin-left: 2px;
                margin-right: 2px;
            }
        }
    }
    .pay {
        display: flex;
        justify-content: center;
        padding-top: 50px;
        padding-bottom: 50px;
        .btn {
            background: #07C160;
            width: 160px;
            height: 34px;
            border-radius: 6px;
            text-align: center;
            line-height: 34px;
            color: #fff;
            font-size: 16px;
        }
    }
    .pwd-input {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 275px;
        .van-password-input {
            width: 290px;
            .van-password-input__item {
                background: #dfdede;
                width: 40px;
                height: 40px;
                box-sizing: border-box;
                border-radius: 4px;
                flex: 1;
            }
        }
        ::v-deep .van-number-keyboard {
            .van-number-keyboard__body {
                padding-left: 1px;
                padding-top: 1px;
                .van-key__wrapper {
                    padding-right: 1px;
                    padding-bottom: 1px;
                    div {
                        height: 60px;
                        border-radius: 0;
                    }
                }
                .van-key__wrapper:nth-of-type(3n) {
                    padding-right: 0;
                }
                .van-key__wrapper:nth-of-type(10) {
                    div {
                        background: #EDEDED;
                        svg {
                            display: none;
                        }
                    }
                }
                .van-key__wrapper:nth-of-type(12) {
                    div {
                        background: #EDEDED;
                        // svg {
                        //     display: none;
                        // }
                    }
                }
            }
        }
    }
    .inputPwdDur {
        margin-top: 80px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #BFBFBF;
        font-size: 18px;
        .text {
            margin-top: 16px;
            margin-bottom: 16px;
        }
        .dotList {
            display: flex;
            div {
                width: 6px;
                height: 6px;
                background: #E5E5E5;
                border-radius: 50%;
                margin-left: 2px;
                margin-right: 2px;
            }
            .active {
                background: #7F7F7F;
            }
            .active1 {
                background: #B2B2B2;
            }
        }
    }
}
.shaoyishaoPop {
  padding: 20px 16px;
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(~"100% - 40px");
  background: #fff;
  color: rgba(0, 0, 0);
  .shaoyishaoPopHeader {
      position: relative;
      color: #000;
      text-align: center;
      margin-bottom: 30px;
      .icon {
          position: absolute;
          left: 0;
          top: 3px;
      }
  }
  .icon {
      width: 16px;
      height: 16px;
      margin-right: 14px;
  }
  .text_main {
      font-size: 16px;
  }
  .text_sub {
      font-size: 14px;
  }
  .cash {
      margin-bottom: 30px;
      color: rgba(0, 0, 0, 0.4);
      .title {
          display: flex;
          align-items: center;
      }
      .text_sub {
          margin-left: 30px;
      }
      span {
          color: #1f517c;
      }
  }
  .money {
      margin-bottom: 30px;
      color: rgba(0, 0, 0, 0.4);
      .title {
          margin-bottom: 10px;
      }
      .content {
          .title {
              display: flex;
              align-items: center;
              margin-bottom: 0;
          }
          .text {
              margin-left: 30px;
          }
      }
  }
  .cardList {
      .cardTitle {
          margin-bottom: 10px;
      }
      .card {
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #F2F2F2;
          .title {
              display: flex;
              align-items: center;
              font-size: 16px;
              .name {
                  flex: 1;
              }
          }
          .active {
              margin-right: 0;
          }
          .tips {
              margin-left: 30px;
              line-height: 16px;
          }
      }
      .avtiveCard {
        border-color: #fff;
      }
  }
  .addCard {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 16px;
      .arrow {
          margin-right: 0;
      }
  }
};


</style>
